import React from 'react';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
import OwlCarousel from 'react-owl-carousel3';
import PlatinumSponsors from '../LaxButton/PlatinumSponsors';
import GoldSponsors from '../LaxButton/GoldSponsors';

const options = {
    loop: true,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    navText: [
        "<i class='icofont-rounded-left'></i>",
        "<i class='icofont-rounded-right'></i>"
    ],
    responsive: {
        0: {
            items:2,
        },
        400: {
            items:3,
        },
        576: {
            items:3,
        },
        768: {
            items:4,
        },
        1200: {
            items:5,
        }
    }
}
 
class Partner extends React.Component {

    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }

    render(){
        return (
            <section className="partner-area ptb-120">
                <div className="container">
                    <div className="section-title">
                        <span>PARTNERZY</span>
                        <h2>Partnerzy<br/>wydarzenia</h2>
                    </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="partner-title platinum-sponsor">
                        <h3>Główny partner ekosystemowy</h3>
                      </div>
                    </div>

                    <div className="logo-grid big logo-gird-flex">
                      <div className="col-lg-12 col-md-12">
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/LOGO_SHP.png")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/LOGO_SHP.png")} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="partner-title platinum-sponsor">
                        <h3>Partner strategiczny</h3>
                      </div>
                    </div>

                    <div className="logo-grid big logo-gird-flex kghm-logo">
                      <div className="col-lg-12 col-md-12">
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/KGHM_PM_Logo_NonMet_RGB_Pos.png")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/KGHM_PM_Logo_NonMet_RGB_Pos.png")} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="partner-title platinum-sponsor">
                        <h3>Partner instytucjonalny</h3>
                      </div>
                    </div>

                    <div className="logo-grid big logo-gird-flex">

                      <div className="col-lg-12 col-md-12">
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/PAIH Grupa PFR.png")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/PAIH Grupa PFR.png")} alt="" />
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12" style={{maxWidth: '800px'}}>
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/logosy.png")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/logosy.png")} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="partner-title platinum-sponsor">
                        <h3>Partner wspierający</h3>
                      </div>
                    </div>

                    <div className="logo-grid big logo-gird-flex">
                      <div className="col-lg-12 col-md-12">
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/BNPP_BL_Q.png")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/BNPP_BL_Q.png")} alt="" />
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/ncbr_akces.png")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/ncbr_akces.png")} alt="" />
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/ncbr_asi.png")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/ncbr_asi.png")} alt="" />
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12" style={{maxWidth: '800px'}}>
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/PARP.jpg")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/PARP.jpg")} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="partner-title platinum-sponsor">
                        <h3>Ambassadorial Event for Vivatechnology</h3>
                      </div>
                    </div>

                    <div className="logo-grid big logo-gird-flex">
                      <div className="col-lg-12 col-md-12">
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/Logo VT 24 (1).png")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/Logo VT 24 (1).png")} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="partner-title platinum-sponsor">
                        <h3>Partnerzy ekosystemowi</h3>
                      </div>
                    </div>

                    <div className="logo-grid">
                      <div className="col-lg-12 col-md-12">
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/3.png")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/3.png")} alt="" />
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/logo_inhub_full_dark@2x.png")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/logo_inhub_full_dark@2x.png")} alt="" />
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/logo-Startup-Poland-rgb.png")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/logo-Startup-Poland-rgb.png")} alt="" />
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/INQUBE.png")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/INQUBE.png")} alt="" />
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/ptk.png")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/ptk.png")} alt="" />
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/2(2).png")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/2(2).png")} alt="" />
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/startup_wroclaw.png")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/startup_wroclaw.png")} alt="" />
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/1(1).png")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/1(1).png")} alt="" />
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/youthbusiness.png")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/youthbusiness.png")} alt="" />
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/ekipa.png")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/ekipa.png")} alt="" />
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/eucon.png")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/eucon.png")} alt="" />
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/fund.png")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/fund.png")} alt="" />
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/huge.png")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/huge.png")} alt="" />
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/startupvoice.png")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/startupvoice.png")} alt="" />
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/psc.png")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/psc.png")} alt="" />
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/star.png")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/star.png")} alt="" />
                          </a>
                        </div>
                      </div>

                    </div>

                    <div className="col-lg-12">
                      <div className="partner-title platinum-sponsor">
                        <h3>Patronat medialny</h3>
                      </div>
                    </div>

                    <div className="logo-grid big logo-gird-flex">
                      <div className="col-lg-12 col-md-12">
                        <div className="partner-item">
                          <a>
                            <img src={require("../../assets/images/gpw/logo/winieta 02- small 1.png")} alt="" />
                            <img src={require("../../assets/images/gpw/logo/winieta 02- small 1.png")} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>

                  </div>
                    
                </div>
            </section>
        );
    }
}
 
export default Partner;