import React from 'react';

class Activity extends React.Component {
    
    render(){
        return (
            <section className="about-area-three ptb-120 bg-image">
                <div className="container">
                    <div className="about-content activity-grid">
                        <div className="activity-grid-span2">
                            <span>AKTYWNOŚCI</span>
                            <h2>Różnorodność aktywności na GPW Venture Network Day</h2>
                            <p>
                              GPW Venture Network Day to wyjątkowe wydarzenie, które kładzie nacisk na synergię pomiędzy dynamicznym ekosystemem giełdowym, a ambitnymi startupowcami.
                              <br/><br/>
                              Tworzymy przestrzeń, w której idee spotykają się z kapitałem, wiedza łączy się z innowacjami, a możliwość partnerstwa staje się rzeczywistością. W sercu tego wydarzenia leży chęć wspólnego kształtowania projektów o międzynarodowym zasięgu i znaczeniu.
                            </p>
                        </div>
                        <div/>
                        <div className="activity-grid-span3">
                            <div>
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_390_11462)">
                                        <path d="M30 25.8001C32.1062 26.2288 34.2944 25.9674 36.2404 25.0547C38.1863 24.142 39.7864 22.6267 40.8036 20.7333C41.8207 18.8398 42.2007 16.6691 41.8872 14.5427C41.5738 12.4163 40.5836 10.4475 39.0634 8.92811C37.5432 7.40869 35.5739 6.41949 33.4474 6.10715C31.3209 5.7948 29.1503 6.17591 27.2574 7.19401C25.3644 8.21211 23.8499 9.813 22.9383 11.7594C22.0266 13.7058 21.7663 15.8942 22.196 18.0001" fill="url(#paint0_linear_390_11462)"/>
                                        <path d="M30 25.8001C32.1062 26.2288 34.2944 25.9674 36.2404 25.0547C38.1863 24.142 39.7864 22.6267 40.8036 20.7333C41.8207 18.8398 42.2007 16.6691 41.8872 14.5427C41.5738 12.4163 40.5836 10.4475 39.0634 8.92811C37.5432 7.40869 35.5739 6.41949 33.4474 6.10715C31.3209 5.7948 29.1503 6.17591 27.2574 7.19401C25.3644 8.21211 23.8499 9.813 22.9383 11.7594C22.0266 13.7058 21.7663 15.8942 22.196 18.0001" stroke="#331FF6" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M29.9992 25.8002L22.1952 18.0022L7.1692 35.1702C6.41864 35.92 5.99666 36.9372 5.99609 37.9981C5.99582 38.5234 6.09901 39.0436 6.29977 39.529C6.50054 40.0144 6.79495 40.4556 7.1662 40.8272C7.53744 41.1988 7.97826 41.4937 8.46346 41.695C8.94867 41.8963 9.46878 42 9.99408 42.0003C11.055 42.0009 12.0726 41.58 12.8232 40.8302L29.9992 25.8002Z" fill="url(#paint1_linear_390_11462)" stroke="#331FF6" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                    <defs>
                                        <linearGradient id="paint0_linear_390_11462" x1="31.9941" y1="6.00098" x2="31.9941" y2="26.001" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#331FF6" stop-opacity="0.3"/>
                                            <stop offset="1" stop-color="#331FF6" stop-opacity="0"/>
                                        </linearGradient>
                                        <linearGradient id="paint1_linear_390_11462" x1="17.9976" y1="18.0022" x2="17.9976" y2="42.0003" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#331FF6" stop-opacity="0.3"/>
                                            <stop offset="1" stop-color="#331FF6" stop-opacity="0"/>
                                        </linearGradient>
                                        <clipPath id="clip0_390_11462">
                                            <rect width="48" height="48" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                <h3>Pitch Studio</h3>
                                <p>
                                  Scena, na której startupy mają szansę zaprezentować swoje projekty bezpośrednio przed renomowanymi inwestorami z Polski. Jeśli jesteś startupem poszukującym finansowania lub inwestorem poszukującym ekscytujących innowacji, to jest to miejsce dla Ciebie.
                                </p>
                            </div>
                            <div>
                                <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M40.5 22.0001C40.0109 18.4806 38.3781 15.2195 35.8533 12.7191C33.3284 10.2188 30.0515 8.61798 26.5273 8.16325C23.0032 7.70852 19.4272 8.42511 16.3504 10.2026C13.2736 11.9802 10.8665 14.72 9.5 18.0001M8.5 10.0001V18.0001H16.5" stroke="#331FF6" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M8.5 26.0002C8.98912 29.5198 10.6219 32.7809 13.1467 35.2813C15.6716 37.7816 18.9485 39.3824 22.4727 39.8371C25.9968 40.2919 29.5728 39.5753 32.6496 37.7978C35.7264 36.0202 38.1335 33.2804 39.5 30.0002M40.5 38.0002V30.0002H32.5" stroke="#331FF6" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M9.22656 24C9.22656 28.0506 10.8357 31.9353 13.6998 34.7995C16.564 37.6637 20.4487 39.2728 24.4993 39.2728C28.5499 39.2728 32.4345 37.6637 35.2987 34.7995C38.1629 31.9353 39.772 28.0506 39.772 24C39.772 19.9494 38.1629 16.0648 35.2987 13.2006C32.4345 10.3364 28.5499 8.72729 24.4993 8.72729C20.4487 8.72729 16.564 10.3364 13.6998 13.2006C10.8357 16.0648 9.22656 19.9494 9.22656 24Z" fill="url(#paint0_linear_390_11467)"/>
                                    <defs>
                                        <linearGradient id="paint0_linear_390_11467" x1="24.4993" y1="8.72729" x2="24.4993" y2="39.2728" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#331FF6" stop-opacity="0.3"/>
                                            <stop offset="1" stop-color="#331FF6" stop-opacity="0"/>
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <h3>Reverse Pitch Studio</h3>
                                <p>
                                  Odwracamy role! Zapraszamy kluczowe spółki notowane na GPW, aby przedstawiły swoje propozycje i oferty dla startupów. Chcesz zobaczyć co duże firmy mają do zaoferowania startupom? To scena, której nie możesz przegapić.
                                </p>
                            </div>
                            <div>
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_390_11473)">
                                        <path d="M8 26.0002C11.5662 26.4241 14.8861 28.0353 17.4255 30.5747C19.9649 33.1141 21.5762 36.4341 22 40.0002C23.7678 38.9811 25.2466 37.5279 26.2965 35.7781C27.3463 34.0283 27.9326 32.0397 28 30.0002C31.3582 28.8189 34.2907 26.6682 36.4267 23.8203C38.5627 20.9723 39.8062 17.5549 40 14.0002C40 12.4089 39.3679 10.8828 38.2426 9.7576C37.1174 8.63239 35.5913 8.00024 34 8.00024C30.4453 8.19406 27.0279 9.43757 24.18 11.5735C21.332 13.7095 19.1814 16.642 18 20.0002C15.9605 20.0676 13.9719 20.6539 12.2221 21.7038C10.4723 22.7537 9.01918 24.2324 8 26.0002Z" fill="url(#paint0_linear_390_11473)" stroke="#331FF6" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M14.001 27.9998C11.9194 29.1749 10.2363 30.9449 9.16727 33.0829C8.09828 35.2209 7.69215 37.6294 8.00096 39.9998C10.3713 40.3086 12.7798 39.9024 14.9178 38.8334C17.0558 37.7644 18.8258 36.0813 20.001 33.9998" fill="url(#paint1_linear_390_11473)"/>
                                        <path d="M14.001 27.9998C11.9194 29.1749 10.2363 30.9449 9.16727 33.0829C8.09828 35.2209 7.69215 37.6294 8.00096 39.9998C10.3713 40.3086 12.7798 39.9024 14.9178 38.8334C17.0558 37.7644 18.8258 36.0813 20.001 33.9998" stroke="#331FF6" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M28 17.9998C28 18.5302 28.2107 19.0389 28.5858 19.414C28.9609 19.789 29.4696 19.9998 30 19.9998C30.5304 19.9998 31.0391 19.789 31.4142 19.414C31.7893 19.0389 32 18.5302 32 17.9998C32 17.4693 31.7893 16.9606 31.4142 16.5855C31.0391 16.2105 30.5304 15.9998 30 15.9998C29.4696 15.9998 28.9609 16.2105 28.5858 16.5855C28.2107 16.9606 28 17.4693 28 17.9998Z" stroke="#331FF6" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                    <defs>
                                        <linearGradient id="paint0_linear_390_11473" x1="24" y1="8.00024" x2="24" y2="40.0002" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#331FF6" stop-opacity="0.3"/>
                                            <stop offset="1" stop-color="#331FF6" stop-opacity="0"/>
                                        </linearGradient>
                                        <linearGradient id="paint1_linear_390_11473" x1="13.9507" y1="27.9998" x2="13.9507" y2="40.1003" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#331FF6" stop-opacity="0.3"/>
                                            <stop offset="1" stop-color="#331FF6" stop-opacity="0"/>
                                        </linearGradient>
                                        <clipPath id="clip0_390_11473">
                                            <rect width="48" height="48" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                <h3>Discovery Studio</h3>
                                <p>
                                  Tu możesz zanurzyć się głęboko w trendach, które definiują świat innowacji. Wysłuchaj liderów branży, poznaj najnowsze badania i analizy oraz odkryj jakie technologie będą dominować w nadchodzących latach.
                                </p>
                            </div>
                            <div>
                                <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_390_11478)">
                                        <path d="M12.3613 13.8721L14.9113 22.3701M26.1253 33.5881L34.6273 36.1381" stroke="#331FF6" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M23.8652 24.634L35.3832 13.116" stroke="#331FF6" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M8.5 11C8.5 11.394 8.5776 11.7841 8.72836 12.1481C8.87913 12.512 9.1001 12.8427 9.37868 13.1213C9.65726 13.3999 9.98797 13.6209 10.3519 13.7716C10.7159 13.9224 11.106 14 11.5 14C11.894 14 12.2841 13.9224 12.6481 13.7716C13.012 13.6209 13.3427 13.3999 13.6213 13.1213C13.8999 12.8427 14.1209 12.512 14.2716 12.1481C14.4224 11.7841 14.5 11.394 14.5 11C14.5 10.606 14.4224 10.2159 14.2716 9.85195C14.1209 9.48797 13.8999 9.15726 13.6213 8.87868C13.3427 8.6001 13.012 8.37913 12.6481 8.22836C12.2841 8.0776 11.894 8 11.5 8C11.106 8 10.7159 8.0776 10.3519 8.22836C9.98797 8.37913 9.65726 8.6001 9.37868 8.87868C9.1001 9.15726 8.87913 9.48797 8.72836 9.85195C8.5776 10.2159 8.5 10.606 8.5 11Z" fill="url(#paint0_linear_390_11478)" stroke="#331FF6" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M34.5 11C34.5 11.7957 34.8161 12.5587 35.3787 13.1213C35.9413 13.6839 36.7044 14 37.5 14C38.2956 14 39.0587 13.6839 39.6213 13.1213C40.1839 12.5587 40.5 11.7957 40.5 11C40.5 10.2044 40.1839 9.44129 39.6213 8.87868C39.0587 8.31607 38.2956 8 37.5 8C36.7044 8 35.9413 8.31607 35.3787 8.87868C34.8161 9.44129 34.5 10.2044 34.5 11Z" fill="url(#paint1_linear_390_11478)" stroke="#331FF6" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M34.5 37C34.5 37.7956 34.8161 38.5587 35.3787 39.1213C35.9413 39.6839 36.7044 40 37.5 40C38.2956 40 39.0587 39.6839 39.6213 39.1213C40.1839 38.5587 40.5 37.7956 40.5 37C40.5 36.2044 40.1839 35.4413 39.6213 34.8787C39.0587 34.3161 38.2956 34 37.5 34C36.7044 34 35.9413 34.3161 35.3787 34.8787C34.8161 35.4413 34.5 36.2044 34.5 37Z" fill="url(#paint2_linear_390_11478)" stroke="#331FF6" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M8.5 31C8.5 33.3869 9.44821 35.6761 11.136 37.364C12.8239 39.0518 15.1131 40 17.5 40C19.8869 40 22.1761 39.0518 23.864 37.364C25.5518 35.6761 26.5 33.3869 26.5 31C26.5 28.6131 25.5518 26.3239 23.864 24.636C22.1761 22.9482 19.8869 22 17.5 22C15.1131 22 12.8239 22.9482 11.136 24.636C9.44821 26.3239 8.5 28.6131 8.5 31Z" fill="url(#paint3_linear_390_11478)" stroke="#331FF6" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                    <defs>
                                        <linearGradient id="paint0_linear_390_11478" x1="11.5" y1="8" x2="11.5" y2="14" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#331FF6" stop-opacity="0.3"/>
                                            <stop offset="1" stop-color="#331FF6" stop-opacity="0"/>
                                        </linearGradient>
                                        <linearGradient id="paint1_linear_390_11478" x1="37.5" y1="8" x2="37.5" y2="14" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#331FF6" stop-opacity="0.3"/>
                                            <stop offset="1" stop-color="#331FF6" stop-opacity="0"/>
                                        </linearGradient>
                                        <linearGradient id="paint2_linear_390_11478" x1="37.5" y1="34" x2="37.5" y2="40" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#331FF6" stop-opacity="0.3"/>
                                            <stop offset="1" stop-color="#331FF6" stop-opacity="0"/>
                                        </linearGradient>
                                        <linearGradient id="paint3_linear_390_11478" x1="17.5" y1="22" x2="17.5" y2="40" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="#331FF6" stop-opacity="0.3"/>
                                            <stop offset="1" stop-color="#331FF6" stop-opacity="0"/>
                                        </linearGradient>
                                        <clipPath id="clip0_390_11478">
                                            <rect width="48" height="48" fill="white" transform="translate(0.5)"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                <h3>Networking Area</h3>
                                <p>
                                  Tu możesz nawiązać bezpośrednie kontakty z innowatorami, przedsiębiorcami i inwestorami, odkrywając nowe możliwości współpracy. Znajdź potencjalnych partnerów biznesowych, wymień doświadczenia i buduj relacje w dynamicznym środowisku polskiego ekosystemu startupowego.
                                </p>
                            </div>
                        </div>

                      </div>
                </div>
            </section>
        );
    }
}
 
export default Activity;