import React from 'react';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
import LaxDiv from '../Shared/LaxDiv';
import {IconArrowNarrowRight, IconMapPin} from "@tabler/icons-react";
 
class ContactLocation extends React.Component {

    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }

    render(){
        return (
            <section className="blog-area blog-section ptb-120 bg-image">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="section-title">
                                <span>LOKALIZACJA I KONTAKT</span>
                                <h2>Znajdź nas,<br/>skontaktuj się z nami</h2>
                            </div>
                            <div className="info-ctn">
                                <div className="icon-ctn">
                                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_268_7908)">
                                            <path d="M22.5 27.5C22.5 29.4891 23.2902 31.3968 24.6967 32.8033C26.1032 34.2098 28.0109 35 30 35C31.9891 35 33.8968 34.2098 35.3033 32.8033C36.7098 31.3968 37.5 29.4891 37.5 27.5C37.5 25.5109 36.7098 23.6032 35.3033 22.1967C33.8968 20.7902 31.9891 20 30 20C28.0109 20 26.1032 20.7902 24.6967 22.1967C23.2902 23.6032 22.5 25.5109 22.5 27.5Z" fill="url(#paint0_linear_268_7908)" stroke="#616FF7" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M43.6874 40.9601L33.0799 51.5676C32.1424 52.5042 30.8714 53.0303 29.5462 53.0303C28.221 53.0303 26.95 52.5042 26.0124 51.5676L15.4024 40.9601C12.6055 38.163 10.7008 34.5994 9.92915 30.7198C9.15753 26.8402 9.55366 22.8189 11.0674 19.1645C12.5812 15.51 15.1447 12.3865 18.4336 10.1889C21.7226 7.99131 25.5893 6.81836 29.5449 6.81836C33.5005 6.81836 37.3673 7.99131 40.6562 10.1889C43.9452 12.3865 46.5086 15.51 48.0224 19.1645C49.5362 22.8189 49.9323 26.8402 49.1607 30.7198C48.3891 34.5994 46.4844 38.163 43.6874 40.9601Z" fill="url(#paint1_linear_268_7908)" stroke="#616FF7" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                        <defs>
                                            <linearGradient id="paint0_linear_268_7908" x1="30" y1="20" x2="30" y2="35" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#331FF6" stop-opacity="0.3"/>
                                                <stop offset="1" stop-color="#331FF6" stop-opacity="0"/>
                                            </linearGradient>
                                            <linearGradient id="paint1_linear_268_7908" x1="29.5449" y1="6.81836" x2="29.5449" y2="53.0303" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#331FF6" stop-opacity="0.3"/>
                                                <stop offset="1" stop-color="#331FF6" stop-opacity="0"/>
                                            </linearGradient>
                                            <clipPath id="clip0_268_7908">
                                                <rect width="60" height="60" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>

                                </div>
                                <div className="content-ctn">
                                    <div>
                                        <h3>Giełda Papierów Wartościowych w Warszawie</h3>
                                        <p>ul. Książęca 4, 00-498 Warszawa</p>
                                    </div>
                                    <a href="https://maps.app.goo.gl/crLXod9rwhbszqC39" target="_blank" className="btn-gpw btn-gpw-small btn-gpw-sec">
                                        Nawiguj
                                        <IconArrowNarrowRight size={16}/>
                                    </a>
                                </div>
                            </div>
                            <div className="info-ctn">
                                <div className="icon-ctn">
                                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_268_3824)">
                                            <path d="M7.5 17.5C7.5 16.1739 8.02678 14.9021 8.96447 13.9645C9.90215 13.0268 11.1739 12.5 12.5 12.5H47.5C48.8261 12.5 50.0979 13.0268 51.0355 13.9645C51.9732 14.9021 52.5 16.1739 52.5 17.5V42.5C52.5 43.8261 51.9732 45.0979 51.0355 46.0355C50.0979 46.9732 48.8261 47.5 47.5 47.5H12.5C11.1739 47.5 9.90215 46.9732 8.96447 46.0355C8.02678 45.0979 7.5 43.8261 7.5 42.5V17.5Z" fill="url(#paint0_linear_268_3824)" stroke="#616FF7" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M7.5 17.5L30 32.5L52.5 17.5" stroke="#616FF7" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                        <defs>
                                            <linearGradient id="paint0_linear_268_3824" x1="30" y1="12.5" x2="30" y2="47.5" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#331FF6" stop-opacity="0.3"/>
                                                <stop offset="1" stop-color="#331FF6" stop-opacity="0"/>
                                            </linearGradient>
                                            <clipPath id="clip0_268_3824">
                                                <rect width="60" height="60" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </div>
                                <div className="content-ctn">
                                    <div>
                                        <h3 className="email" style={{fontSize: '16px'}}>gpwventurenetworkday@gpw.pl</h3>
                                    </div>
                                    <a href="mailto:gpwventurenetworkday@gpw.pl" className="btn-gpw btn-gpw-small btn-gpw-sec">
                                        Wyślij maila
                                        <IconArrowNarrowRight size={16}/>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6" >
                            <div className="google-map-code">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2443.7321495649094!2d21.021084476663574!3d52.23008397198664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471eccf74a4c0c4b%3A0x8a4b1aa6b2117dc8!2sCentrum%20Gie%C5%82dowe%20S.A.!5e0!3m2!1spl!2sde!4v1694102328520!5m2!1spl!2sde"
                                    height="400" allowFullScreen="" loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                    style={{position: 'absolute', left: 0, right: 0, width: '100%', height: '400px'}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default ContactLocation;