import React from 'react'

class GoTop extends React.Component {

    state = {
        intervalId: 0,
        thePosition: false
    };

    componentDidMount() {
        this._isMounted = true;
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                this.setState({ thePosition: true })
            } else {
                this.setState({ thePosition: false })
            }
        });
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    
    onScrollStep = () => {
        if (window.pageYOffset === 0){
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
    }

    scrollToTop = () => {
        let intervalId = setInterval(this.onScrollStep, this.props.delayInMs);
        this.setState({ intervalId: intervalId });
    }

    renderGoTopIcon = () => {
        if (this.state.thePosition){
            return (
                <div className="back-to-top2" onClick={this.scrollToTop}>
                    <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1.70508" width="62" height="61.3751" rx="30.6876" fill="#331FF6" fill-opacity="0.1"/>
                        <g clip-path="url(#clip0_1516_2066)">
                            <path d="M32 19.8711V45.5378" stroke="#331FF6" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M43 30.8711L32 19.8711" stroke="#331FF6" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M21 30.8711L32 19.8711" stroke="#331FF6" stroke-width="2.75" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <rect x="1" y="1.70508" width="62" height="61.3751" rx="30.6876" stroke="#331FF6" stroke-width="2"/>
                        <defs>
                            <clipPath id="clip0_1516_2066">
                                <rect width="44" height="44" fill="white" transform="translate(10 10.7051)"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            )
        }
    }

    render(){
        return (
            <React.Fragment>
                {this.renderGoTopIcon()}
            </React.Fragment>
        )
    }
}

export default GoTop;
