import React from 'react';
import lax from 'lax.js';
import MainBanner from '../HomeThree/MainBanner';
import About from '../HomeThree/About';
import Speakers from '../HomeThree/Speakers';
import EventSchedules from '../HomeDefault/EventSchedules';
import FunFact from '../Common/FunFact';
import Partner from '../Common/Partner';
import ContactLocation from '../HomeThree/LatestNews';
import Footer from '../Common/Footer';
import GoTop from '../Shared/GoTop';
import Cta from '../Common/Cta';
import Activity from "../HomeThree/Activity";
import Uczestnicy from "../HomeThree/Uczestnicy";
 
class HomeThree extends React.Component {
    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }
    render(){
        return (
            <React.Fragment>
                <MainBanner />
                <About />
                <FunFact />

                <Activity/>
                <Speakers />

                <Uczestnicy/>

                <EventSchedules />

                <Partner />

                <Cta />

                <ContactLocation />
                {/* Footer Area */}
                <Footer />

                <GoTop scrollStepInPx="50" delayInMs="16.66" />
            </React.Fragment>
        );
    }
}
 
export default HomeThree;