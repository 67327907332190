import React from 'react';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
import {IconClockHour5, IconDownload, IconMapPin, IconPresentation, IconUser} from "@tabler/icons-react";


const pitch_studio_agenda = [
  {
    start: '8:00',
    time: 'Cały dzień',
    title: 'Otwarcie rejestracji',
    type: 'Organizacja'
  },
  {
    start: '9:00',
    end: '10:00',
    time: '1h',
    title: 'Poranny poczęstunek w strefie VIP',
    type: 'Organizacja'
  },
  {
    start: '10:00',
    end: '10:20',
    time: '20min',
    title: 'Oficjalne otwarcie wydarzenia',
    type: 'Organizacja'
  },
  {start: '10:20', end: '10:23', time: '3min', title: 'Pitch Session vol.1', type: 'Prelekcja'},
  {start: '10:23', end: '10:35', time: '12min', title: 'Combo', type: 'Prelekcja'},
  {start: '10:35', end: '10:47', time: '12min', title: 'Future Genius', type: 'Prelekcja'},
  {start: '10:47', end: '10:59', time: '12min', title: 'Neurodio', type: 'Prelekcja'},
  {start: '10:59', end: '11:11', time: '12min', title: 'CliniNote', type: 'Prelekcja'},
  {start: '11:11', end: '11:23', time: '12min', title: 'SCImune', type: 'Prelekcja'},
  {
    start: '11:23',
    end: '11:38',
    time: '15min',
    title: 'EPI-CAN - wczesna diagnostyka nowotworów',
    type: 'Prelekcja'
  },
  {
    start: '11:38',
    end: '11:50',
    time: '12min',
    title: 'Ledholo',
    type: 'Prelekcja'
  },
  {
    start: '11:50',
    end: '11:52',
    time: '2min',
    title: 'Pitch Session vol.2',
    type: 'Prelekcja'
  },
  {
    start: '11:52',
    end: '12:04',
    time: '12min',
    title: 'PhiLabs',
    type: 'Prelekcja'
  },
  {
    start: '12:04',
    end: '12:16',
    time: '12min',
    title: 'Magly',
    type: 'Prelekcja'
  },
  {
    start: '12:16',
    end: '12:28',
    time: '12min',
    title: 'MyEgg',
    type: 'Prelekcja'
  },
  {
    start: '12:28',
    end: '12:40',
    time: '12min',
    title: 'RIFFsec',
    type: 'Prelekcja'
  },
  {start: '12:40', end: '12:52', time: '12min', title: 'Telemedical Innovations', type: 'Prelekcja'},
  {start: '12:52', end: '13:04', time: '12min', title: 'Autonomy Now', type: 'Prelekcja'},
  {start: '13:04', end: '13:16', time: '12min', title: 'Delivery Couple', type: 'Prelekcja'},
  {start: '13:16', end: '13:28', time: '12min', title: 'INKsearch', type: 'Prelekcja'},
  {start: '13:28', end: '13:40', time: '12min', title: 'Plan Be Eco', type: 'Prelekcja'},
  {start: '13:40', end: '13:52', time: '12min', title: 'Phygit', type: 'Prelekcja'},
  {start: '13:52', end: '14:40', time: '48min', title: 'Przerwa', type: 'Organizacja'},
  {start: '14:40', end: '14:50', time: '10min', title: 'Opening Remarks', type: 'Prelekcja', ppl: ['Paulina Brym', 'Ewa Geresz', 'David Lewis']},
  {start: '14:50', end: '15:00', time: '10min', title: 'BANK of Memories', type: 'Prelekcja'},
  {start: '15:00', end: '15:10', time: '10min', title: 'BUNZI', type: 'Prelekcja'},
  {start: '15:10', end: '15:20', time: '10min', title: 'DIVERZUM', type: 'Prelekcja'},
  {start: '15:20', end: '15:30', time: '10min', title: 'NABIO Medical Technologies', type: 'Prelekcja'},
  {start: '15:30', end: '15:40', time: '10min', title: 'YOUTH EMPOWER', type: 'Prelekcja'},
  {start: '15:40', end: '15:50', time: '10min', title: 'Skillgo', type: 'Prelekcja'},
  {start: '15:50', end: '16:00', time: '10min', title: 'AR Platform', type: 'Prelekcja'},
  {start: '16:00', end: '16:10', time: '10min', title: 'WATER IN SALT', type: 'Prelekcja'},
  {start: '16:10', end: '16:20', time: '10min', title: 'Nasdaq Remarks', type: 'Prelekcja', ppl: ['Sylvester Andersen']},
  {start: '16:20', end: '16:40', time: '20min', title: 'Przerwa', type: 'Organizacja'},
  {start: '16:40', end: '16:45', time: '5min', title: 'Winner Announcement', type: 'Organizacja'},
  {start: '16:45', end: '16:50', time: '5min', title: 'Closing Nasdaq remarks, Closing StartupHub and CEU remarks', type: 'Prelekcja'},
  {start: '16:50', end: '16:52', time: '2min', title: 'Pitch Session vol.3', type: 'Organizacja'},
  {start: '16:52', end: '17:04', time: '12min', title: 'inStreamly', type: 'Prelekcja'},
  {start: '17:04', end: '17:16', time: '12min', title: 'Nanosci', type: 'Prelekcja'},
  {start: '17:16', end: '17:28', time: '12min', title: 'Liki Mobile Solutions', type: 'Prelekcja'},
  {start: '17:28', end: '17:32', time: '4min', title: 'Salesbook', type: 'Prelekcja'},
  {start: '17:32', end: '17:50', time: '18min', title: 'Przerwa', type: 'Organizacja'},
  {start: '17:50', end: '18:15', time: '25min', title: 'Zakończenie', type: 'Organizacja'},
  {start: '18:15', title: 'Networking', type: 'Organizacja'},
]

const reverse_pitch_studio_agenda = [
  {start: '11:55', end: '12:05', time: '10min', title: 'Reverse Pitch Session vol.1', type: 'Organizacja'},
  {start: '12:05', end: '12:25', time: '20min', title: 'Prezentacja BNP Paribas – Bankowanie z Firmami Innowacyjnymi', type: 'Prelekcja'},
  {start: '12:25', end: '12:45', time: '20min', title: 'Prezentacja GPW – NewConnect gdzie innowacja spotyka się z kapitałem', type: 'Prelekcja'},
  {start: '12:45', end: '13:05', time: '20min', title: 'Prezentacja Alior Bank - Innowacje w Alior Banku. Alior Bank i startupy', type: 'Prelekcja'},
  {start: '13:05', end: '13:25', time: '20min', title: 'Prezentacja PKO BP - Jak napędzamy innowacje w PKO Banku Polskim', type: 'Prelekcja'},
  {start: '13:25', end: '13:45', time: '20min', title: 'Prezentacja Orange – Dlaczego warto pracować z Orange?', type: 'Prelekcja'},
  {start: '13:45', end: '14:05', time: '20min', title: 'Prezentacja PZU - PZU Ready for Startups', type: 'Prelekcja'},
  {start: '14:05', end: '14:25', time: '20min', title: 'Orlen Skylight accelerator – współpraca ORLENu ze startupami', type: 'Prelekcja'},
  {start: '14:25', end: '15:00', time: '35min', title: 'Przerwa', type: 'Organizacja'},
  {start: '15:00', end: '15:05', time: '5min', title: 'Reverse Pitch Session vol.2', type: 'Organizacja'},
  {start: '15:05', end: '15:25', time: '20min', title: 'Prezentacja PARP – Wybrane instrumenty wsparcia startupów w ramach Funduszy Europejskich 2021-2027', type: 'Prelekcja'},
  {start: '15:25', end: '15:45', time: '20min', title: 'Prezentacja PAIH', type: 'Prelekcja'},
  {start: '15:45', end: '16:05', time: '20min', title: 'Prezentacja ARP - ESA BIC Poland – kosmiczne miejsce, w którym pomysły zmieniają się w biznes', type: 'Prelekcja'},
  {start: '16:05', end: '16:25', time: '20min', title: 'Prezentacja ARP - BiznesPortal – nowe narzędzie ARP dla przedsiębiorców', type: 'Prelekcja'},
  {start: '16:25', end: '16:45', time: '20min', title: 'Prezentacja NCBR Investment Fund ASI - Koinwestycje w ekspansję innowacji', type: 'Prelekcja'},
  {start: '16:45', end: '17:05', time: '20min', title: 'Prezentacja NCBR Akces - unikalny program akceleracyjny', type: 'Prelekcja'},
]

class EventSchedules extends React.Component {

  state = {
    currentStage: 'PITCH STUDIO'
  }

    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }

    openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabs_item");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByTagName("li");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace("current", "");
        }

        document.getElementById(tabNmae).style.display = "block";
        evt.currentTarget.className += "current";
    }

    render(){
        return (
            <section className="schedule-area bg-image ptb-120">
                <div className="container">
                    <div className="section-title">
                        <div className="section-title-wtih-btn">
                            <div>
                                <span>AGENDA</span>
                                <h2>Plan wydarzenia GPW<br/>Venture Network Day</h2>
                            </div>
                            <div className="button-box">
                                {/*<a href="https://www.gpw.pl/gvnd" className="btn-gpw btn-gpw-small btn-gpw-main">Bilet onsite</a>*/}
                                <a href="https://www.gpw.pl/gvnd" className="btn-gpw btn-gpw-small btn-gpw-sec">Bilet online</a>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-lg-12">
                            <div className="tab">
                                <div className="tab_content">
                                    <div id="tab1" className="tabs_item">
                                        <ul className="accordion">

                                          <li className="accordion-item tmp-agenda-ctn">
                                            <h3>
                                              Wybierz scenę
                                            </h3>
                                            <div className="scene-grid">
                                              <div
                                                className={`first ${this.state.currentStage === 'PITCH STUDIO' ? 'selected' : ''}`}
                                                onClick={() => this.setState({currentStage: 'PITCH STUDIO'})}
                                              >
                                                PITCH STUDIO
                                              </div>
                                              <div
                                                className={`${this.state.currentStage === 'REVERSE PITCH STUDIO' ? 'selected-2' : ''}`}
                                                onClick={() => this.setState({currentStage: 'REVERSE PITCH STUDIO'})}
                                              >
                                                REVERSE PITCH STUDIO
                                              </div>
                                              <div
                                                className={`${this.state.currentStage === 'DISCOVERY STUDIO' ? 'selected-3' : ''}`}
                                                onClick={() => this.setState({currentStage: 'DISCOVERY STUDIO'})}
                                              >
                                                DISCOVERY STUDIO
                                              </div>
                                            </div>
                                          </li>

                                          {
                                            this.state.currentStage === 'PITCH STUDIO' &&
                                            (
                                              <>
                                                {
                                                  pitch_studio_agenda.map((item) => (
                                                    <li className="accordion-item">
                                                      <div className="info-ctn">
                                                        <div className="type">
                                                          <div>
                                                            <IconPresentation size={24}/>
                                                          </div>
                                                          <span>{item.type}</span>
                                                        </div>
                                                        <p>{item.start} {item.end !== undefined && ' - '} {item.end}</p>
                                                        <div className="time">
                                                          {item.time !== undefined && <IconClockHour5/>}
                                                          {item.time}
                                                        </div>
                                                      </div>
                                                      <div className="accordion-title" to="#">
                                                        <div className="schedule-info">
                                                          <h3>{item.title}</h3>
                                                          {/*<p>Przywitanie uczestników, Wydawanie identyfikatorów i materiałów konferencyjnych Przywitanie uczestników, Wydawanie identyfikatorów i materiałów konferencyjnych</p>*/}
                                                          {/*<ul>*/}
                                                          {/*    <li><IconUser/>Robert Pillar</li>*/}
                                                          {/*    <li><IconMapPin/>Sala 3.01</li>*/}
                                                          {/*</ul>*/}
                                                        </div>
                                                      </div>
                                                    </li>
                                                  ))
                                                }
                                              </>
                                            )
                                          }

                                          {
                                            this.state.currentStage === 'REVERSE PITCH STUDIO' &&
                                            (
                                              <>
                                                {
                                                  reverse_pitch_studio_agenda.map((item) => (
                                                    <li className="accordion-item">
                                                      <div className="info-ctn">
                                                        <div className="type">
                                                          <div>
                                                            <IconPresentation size={24}/>
                                                          </div>
                                                          <span>{item.type}</span>
                                                        </div>
                                                        <p>{item.start} {item.end !== undefined && ' - '} {item.end}</p>
                                                        <div className="time">
                                                          {item.time !== undefined && <IconClockHour5/>}
                                                          {item.time}
                                                        </div>
                                                      </div>
                                                      <div className="accordion-title" to="#">
                                                        <div className="schedule-info">
                                                          <h3>{item.title}</h3>
                                                          {/*<p>Przywitanie uczestników, Wydawanie identyfikatorów i materiałów konferencyjnych Przywitanie uczestników, Wydawanie identyfikatorów i materiałów konferencyjnych</p>*/}
                                                          {/*<ul>*/}
                                                          {/*    <li><IconUser/>Robert Pillar</li>*/}
                                                          {/*    <li><IconMapPin/>Sala 3.01</li>*/}
                                                          {/*</ul>*/}
                                                        </div>
                                                      </div>
                                                    </li>
                                                  ))
                                                }
                                              </>
                                            )
                                          }

                                          {
                                            this.state.currentStage === 'DISCOVERY STUDIO' &&
                                            <>
                                              <li className="accordion-item">
                                                <div className="info-ctn">
                                                  <div className="type">
                                                    <div>
                                                      <IconPresentation size={24}/>
                                                    </div>
                                                    <span>Organizacja</span>
                                                  </div>
                                                  <p>10:20 - 10:30</p>
                                                  <div className="time">
                                                    <IconClockHour5/>
                                                    10min
                                                  </div>
                                                </div>
                                                <div className="accordion-title" to="#">
                                                  <div className="schedule-info">
                                                    <h3>Otwarcie Discovery Studio</h3>
                                                    {/*<p>Przywitanie uczestników, Wydawanie identyfikatorów i materiałów konferencyjnych Przywitanie uczestników, Wydawanie identyfikatorów i materiałów konferencyjnych</p>*/}
                                                    {/*<ul>*/}
                                                    {/*    <li><IconUser/>Robert Pillar</li>*/}
                                                    {/*    <li><IconMapPin/>Sala 3.01</li>*/}
                                                    {/*</ul>*/}
                                                  </div>
                                                </div>
                                              </li>
                                              <li className="accordion-item">
                                                <div className="info-ctn">
                                                  <div className="type">
                                                    <div>
                                                      <IconPresentation size={24}/>
                                                    </div>
                                                    <span>Prelekcja</span>
                                                  </div>
                                                  <p>10:30 - 11:30</p>
                                                  <div className="time">
                                                    <IconClockHour5/>
                                                    1h
                                                  </div>
                                                </div>
                                                <div className="accordion-title" to="#">
                                                  <div className="schedule-info">
                                                    <h3>Prezentacja raportu Fundacji Startup Poland </h3>
                                                    <p>
                                                      Partnerstwa innowacyjne jako ważny element strategii firmy - podsumowanie raportu Fundacji Startup Poland “Jeśli nie CVC to co?”
                                                      <br/> Moderator: Tomasz Snażyk
                                                    </p>
                                                    <ul>
                                                        <li><IconUser/>Marek Myszka</li>
                                                        <li><IconUser/>Paulina Skrzypińska</li>
                                                        <li><IconUser/>Monika Borowiecka</li>
                                                        <li><IconUser/>Mateusz Stasiak</li>
                                                        <li><IconUser/>Ignacy Bobruk</li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </li>
                                              <li className="accordion-item">
                                                <div className="info-ctn">
                                                  <div className="type">
                                                    <div>
                                                      <IconPresentation size={24}/>
                                                    </div>
                                                    <span>Prelekcja</span>
                                                  </div>
                                                  <p>11:30 - 12:30</p>
                                                  <div className="time">
                                                    <IconClockHour5/>
                                                    1h
                                                  </div>
                                                </div>
                                                <div className="accordion-title" to="#">
                                                  <div className="schedule-info">
                                                    <h3>Ścieżki finansowania startupów</h3>
                                                    <p>
                                                      Moderator: Paulina Brym
                                                    </p>
                                                    <ul>
                                                      <li><IconUser/>Adrian Kutnik</li>
                                                      <li><IconUser/>Dominik Krawczyk</li>
                                                      <li><IconUser/>Michał Olszewski</li>
                                                      <li><IconUser/>Ksenia Wójcik-Karasiewicz</li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </li>
                                              <li className="accordion-item">
                                                <div className="info-ctn">
                                                  <div className="type">
                                                    <div>
                                                      <IconPresentation size={24}/>
                                                    </div>
                                                    <span>Prelekcja</span>
                                                  </div>
                                                  <p>12:30 - 13:30</p>
                                                  <div className="time">
                                                    <IconClockHour5/>
                                                    1h
                                                  </div>
                                                </div>
                                                <div className="accordion-title" to="#">
                                                  <div className="schedule-info">
                                                    <h3>Tworzenie startupów z mocą impactu - moda, biznes czy konieczność?</h3>
                                                    <p>Moderator: Adrian Migoń</p>
                                                    <ul>
                                                        <li><IconUser/>Zofia Dzik</li>
                                                        <li><IconUser/>Krzysztof Grochowski</li>
                                                        <li><IconUser/>Dorota Matczak</li>
                                                        <li><IconUser/>Piotr Gębala</li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </li>
                                              <li className="accordion-item">
                                                <div className="info-ctn">
                                                  <div className="type">
                                                    <div>
                                                      <IconPresentation size={24}/>
                                                    </div>
                                                    <span>Prelekcja</span>
                                                  </div>
                                                  <p>13:30 - 14:30</p>
                                                  <div className="time">
                                                    <IconClockHour5/>
                                                    1h
                                                  </div>
                                                </div>
                                                <div className="accordion-title" to="#">
                                                  <div className="schedule-info">
                                                    <h3>Polska – Ukraina jak wspólnie możemy rozwijać innowacje, które zmienią świat (panel w języku angielskim)</h3>
                                                    <p>Moderator: Andrii Romanchuk</p>
                                                    <ul>
                                                        <li><IconUser/>Łukasz Wawak</li>
                                                        <li><IconUser/>Roman Nikitov</li>
                                                        <li><IconUser/>Eugene Nayshtetik</li>
                                                        <li><IconUser/>Albert Świerczyński</li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </li>

                                              <li className="accordion-item">
                                                <div className="info-ctn">
                                                  <div className="type">
                                                    <div>
                                                      <IconPresentation size={24}/>
                                                    </div>
                                                    <span>Prelekcja</span>
                                                  </div>
                                                  <p>14:30 - 15:30</p>
                                                  <div className="time">
                                                    <IconClockHour5/>
                                                    1h
                                                  </div>
                                                </div>
                                                <div className="accordion-title" to="#">
                                                  <div className="schedule-info">
                                                    <h3>Komercjalizacja projektów naukowych</h3>
                                                    <p>Moderator: Michał Misztal</p>
                                                    <ul>
                                                        <li><IconUser/>Hubert Pachciarek</li>
                                                        <li><IconUser/>Tomasz Wojdacz</li>
                                                        <li><IconUser/>Kacper Raciborski</li>
                                                        <li><IconUser/>Magdalena Bohusz-Boguszewska</li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </li>

                                              <li className="accordion-item">
                                                <div className="info-ctn">
                                                  <div className="type">
                                                    <div>
                                                      <IconPresentation size={24}/>
                                                    </div>
                                                    <span>Prelekcja</span>
                                                  </div>
                                                  <p>15:30 - 16:30</p>
                                                  <div className="time">
                                                    <IconClockHour5/>
                                                    1h
                                                  </div>
                                                </div>
                                                <div className="accordion-title" to="#">
                                                  <div className="schedule-info">
                                                    <h3>GoGlobal - Jak wspierać budowę globalnych startupów</h3>
                                                    <p>Moderator: Witold Wiliński</p>
                                                    <ul>
                                                        <li><IconUser/>Paweł Pietrasiński</li>
                                                        <li><IconUser/>Michał Heromiński</li>
                                                        <li><IconUser/>Wojciech Nowicki</li>
                                                        <li><IconUser/>Edyta Hryniecka</li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </li>

                                              <li className="accordion-item">
                                                <div className="info-ctn">
                                                  <div className="type">
                                                    <div>
                                                      <IconPresentation size={24}/>
                                                    </div>
                                                    <span>Organizacja</span>
                                                  </div>
                                                  <p>16:30</p>
                                                  {/*<div className="time">*/}
                                                  {/*  <IconClockHour5/>*/}
                                                  {/*  1h*/}
                                                  {/*</div>*/}
                                                </div>
                                                <div className="accordion-title" to="#">
                                                  <div className="schedule-info">
                                                    <h3>Zakończenie</h3>
                                                    {/*<p>Przywitanie uczestników, Wydawanie identyfikatorów i materiałów konferencyjnych Przywitanie uczestników, Wydawanie identyfikatorów i materiałów konferencyjnych</p>*/}
                                                    {/*<ul>*/}
                                                    {/*    <li><IconUser/>Robert Pillar</li>*/}
                                                    {/*    <li><IconMapPin/>Sala 3.01</li>*/}
                                                    {/*</ul>*/}
                                                  </div>
                                                </div>
                                              </li>
                                            </>
                                          }

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*<div className="col-lg-12">*/}
                        {/*    <div className="btn-box">*/}
                        {/*        <Link to="#" className="btn-gpw btn-gpw-small btn-gpw-sec">*/}
                        {/*            <IconDownload/>*/}
                        {/*            Pobierz agendę PDF*/}
                        {/*        </Link>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </section>
        );
    }
}
 
export default EventSchedules;


const TmpAgenda = () => (
  <div className="tmp-agenda-ctn tmp-agenda-ctn2">
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.334 23.3334C13.334 21.5653 14.0364 19.8696 15.2866 18.6194C16.5368 17.3691 18.2325 16.6667 20.0007 16.6667H60.0006C61.7688 16.6667 63.4645 17.3691 64.7147 18.6194C65.9649 19.8696 66.6673 21.5653 66.6673 23.3334V63.3334C66.6673 65.1015 65.9649 66.7972 64.7147 68.0475C63.4645 69.2977 61.7688 70.0001 60.0006 70.0001H20.0007C18.2325 70.0001 16.5368 69.2977 15.2866 68.0475C14.0364 66.7972 13.334 65.1015 13.334 63.3334V23.3334Z" fill="url(#paint0_linear_390_12807)" stroke="#331FF6" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M53.334 10V23.3333Z" fill="url(#paint1_linear_390_12807)"/>
      <path d="M53.334 10V23.3333" stroke="#331FF6" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M26.666 10V23.3333Z" fill="url(#paint2_linear_390_12807)"/>
      <path d="M26.666 10V23.3333" stroke="#331FF6" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.334 36.6667H66.6673Z" fill="url(#paint3_linear_390_12807)"/>
      <path d="M13.334 36.6667H66.6673" stroke="#331FF6" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
      <defs>
        <linearGradient id="paint0_linear_390_12807" x1="40.0007" y1="16.6667" x2="40.0007" y2="70.0001" gradientUnits="userSpaceOnUse">
          <stop stop-color="#331FF6" stop-opacity="0.3"/>
          <stop offset="1" stop-color="#331FF6" stop-opacity="0"/>
        </linearGradient>
        <linearGradient id="paint1_linear_390_12807" x1="53.834" y1="10" x2="53.834" y2="23.3333" gradientUnits="userSpaceOnUse">
          <stop stop-color="#331FF6" stop-opacity="0.3"/>
          <stop offset="1" stop-color="#331FF6" stop-opacity="0"/>
        </linearGradient>
        <linearGradient id="paint2_linear_390_12807" x1="27.166" y1="10" x2="27.166" y2="23.3333" gradientUnits="userSpaceOnUse">
          <stop stop-color="#331FF6" stop-opacity="0.3"/>
          <stop offset="1" stop-color="#331FF6" stop-opacity="0"/>
        </linearGradient>
        <linearGradient id="paint3_linear_390_12807" x1="40.0007" y1="36.6667" x2="40.0007" y2="37.6667" gradientUnits="userSpaceOnUse">
          <stop stop-color="#331FF6" stop-opacity="0.3"/>
          <stop offset="1" stop-color="#331FF6" stop-opacity="0"/>
        </linearGradient>
      </defs>
    </svg>
    <h3>
      Agenda dostępna wkrótce
    </h3>
    <p>
      Wróć do nas niedługo, żeby zapoznać się
      <br/>z dokładnym planem wydarzenia
    </p>
  </div>
)