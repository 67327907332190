import React from 'react';
import { Link } from 'react-router-dom';
import lax from 'lax.js';
import LaxDiv from '../Shared/LaxDiv';
 
class Speakers extends React.Component {

    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }

    render(){
        return (
            <section className="speakers-area-two ptb-120" style={{position: 'relative'}}>
                <div className="theme1">
                    <svg width="324" height="366" viewBox="0 0 324 366" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.2" clip-path="url(#clip0_1523_4409)">
                            <path d="M235.248 162.534C205.719 162.534 181.782 138.597 181.782 109.069C181.782 79.5408 205.719 55.6035 235.248 55.6035C264.776 55.6035 288.713 79.5408 288.713 109.069C288.713 138.597 264.776 162.534 235.248 162.534Z" stroke="#51C7FF" stroke-width="3" stroke-miterlimit="10"/>
                            <path d="M227.111 180.295C191.021 180.295 161.765 151.039 161.765 114.95C161.765 78.8606 191.021 49.6045 227.111 49.6045C263.2 49.6045 292.456 78.8606 292.456 114.95C292.456 151.039 263.2 180.295 227.111 180.295Z" stroke="#51C7FF" stroke-width="3" stroke-miterlimit="10"/>
                            <path d="M218.973 198.056C176.323 198.056 141.748 163.481 141.748 120.831C141.748 78.1804 176.323 43.6055 218.973 43.6055C261.623 43.6055 296.198 78.1804 296.198 120.831C296.198 163.481 261.623 198.056 218.973 198.056Z" stroke="#51C7FF" stroke-width="3" stroke-miterlimit="10"/>
                            <path d="M210.836 215.818C161.625 215.818 121.731 175.924 121.731 126.713C121.731 77.5012 161.625 37.6074 210.836 37.6074C260.048 37.6074 299.941 77.5012 299.941 126.713C299.941 175.924 260.048 215.818 210.836 215.818Z" stroke="#51C7FF" stroke-width="3" stroke-miterlimit="10"/>
                            <path d="M202.698 233.579C146.926 233.579 101.713 188.366 101.713 132.594C101.713 76.8211 146.926 31.6084 202.698 31.6084C258.471 31.6084 303.684 76.8211 303.684 132.594C303.684 188.366 258.471 233.579 202.698 233.579Z" stroke="#51C7FF" stroke-width="3" stroke-miterlimit="10"/>
                            <path d="M194.55 251.351C132.21 251.351 81.6737 200.814 81.6737 138.475C81.6737 76.1349 132.21 25.5986 194.55 25.5986C256.889 25.5986 307.426 76.1349 307.426 138.475C307.426 200.814 256.889 251.351 194.55 251.351Z" stroke="#51C7FF" stroke-width="3" stroke-miterlimit="10"/>
                            <path d="M186.413 269.112C117.512 269.112 61.6569 213.256 61.6569 144.356C61.6569 75.4548 117.512 19.5996 186.413 19.5996C255.314 19.5996 311.169 75.4548 311.169 144.356C311.169 213.256 255.314 269.112 186.413 269.112Z" stroke="#51C7FF" stroke-width="3" stroke-miterlimit="10"/>
                            <path d="M178.275 286.874C102.813 286.874 41.6391 225.7 41.6391 150.238C41.6391 74.7756 102.813 13.6016 178.275 13.6016C253.737 13.6016 314.911 74.7756 314.911 150.238C314.911 225.7 253.737 286.874 178.275 286.874Z" stroke="#51C7FF" stroke-width="3" stroke-miterlimit="10"/>
                            <path d="M170.138 304.635C88.1151 304.635 21.6222 238.142 21.6222 156.119C21.6222 74.0954 88.1151 7.60254 170.138 7.60254C252.161 7.60254 318.654 74.0954 318.654 156.119C318.654 238.142 252.161 304.635 170.138 304.635Z" stroke="#51C7FF" stroke-width="3" stroke-miterlimit="10"/>
                            <path d="M162 322.396C73.4162 322.396 1.6044 250.584 1.6044 162C1.6044 73.4153 73.4162 1.60352 162 1.60352C250.585 1.60352 322.396 73.4153 322.396 162C322.396 250.584 250.585 322.396 162 322.396Z" stroke="#51C7FF" stroke-width="3" stroke-miterlimit="10"/>
                        </g>
                        <g clip-path="url(#clip1_1523_4409)">
                            <path d="M165 302.908L144.292 282L123.32 303.174L102.708 282.364L82 303.274L102.609 324.081L82.1609 344.726L102.872 365.636L123.32 344.992L144.128 366L164.839 345.092L144.028 324.081L165 302.908Z" fill="url(#paint0_linear_1523_4409)"/>
                        </g>
                        <defs>
                            <linearGradient id="paint0_linear_1523_4409" x1="123.5" y1="282" x2="123.5" y2="366" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#51C7FF"/>
                                <stop offset="1" stop-color="#331FF6"/>
                            </linearGradient>
                            <clipPath id="clip0_1523_4409">
                                <rect width="324" height="324" fill="white" transform="matrix(-1 0 0 1 324 0)"/>
                            </clipPath>
                            <clipPath id="clip1_1523_4409">
                                <rect width="83" height="84" fill="white" transform="translate(82 282)"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>

                <div className="theme2">
                    <svg width="259" height="437" viewBox="0 0 259 437" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.2" clip-path="url(#clip0_1524_4410)">
                            <path d="M-19.0858 246.132C15.8193 246.132 44.1155 217.836 44.1155 182.931C44.1155 148.026 15.8193 119.729 -19.0858 119.729C-53.9909 119.729 -82.2871 148.026 -82.2871 182.931C-82.2871 217.836 -53.9909 246.132 -19.0858 246.132Z" stroke="#331FF6" stroke-width="3" stroke-miterlimit="10"/>
                            <path d="M-9.46629 267.128C33.1948 267.128 67.7784 232.544 67.7784 189.883C67.7784 147.222 33.1948 112.639 -9.46629 112.639C-52.1273 112.639 -86.7109 147.222 -86.7109 189.883C-86.7109 232.544 -52.1273 267.128 -9.46629 267.128Z" stroke="#331FF6" stroke-width="3" stroke-miterlimit="10"/>
                            <path d="M0.153221 288.124C50.5702 288.124 91.4412 247.253 91.4412 196.836C91.4412 146.419 50.5702 105.548 0.153221 105.548C-50.2637 105.548 -91.1348 146.419 -91.1348 196.836C-91.1348 247.253 -50.2637 288.124 0.153221 288.124Z" stroke="#331FF6" stroke-width="3" stroke-miterlimit="10"/>
                            <path d="M9.77273 309.119C67.9456 309.119 115.104 261.96 115.104 203.787C115.104 145.614 67.9456 98.4561 9.77273 98.4561C-48.4002 98.4561 -95.5586 145.614 -95.5586 203.787C-95.5586 261.96 -48.4002 309.119 9.77273 309.119Z" stroke="#331FF6" stroke-width="3" stroke-miterlimit="10"/>
                            <path d="M19.3913 330.115C85.3201 330.115 138.766 276.669 138.766 210.74C138.766 144.811 85.3201 91.3652 19.3913 91.3652C-46.5375 91.3652 -99.9834 144.811 -99.9834 210.74C-99.9834 276.669 -46.5375 330.115 19.3913 330.115Z" stroke="#331FF6" stroke-width="3" stroke-miterlimit="10"/>
                            <path d="M29.0234 351.123C102.715 351.123 162.454 291.384 162.454 217.692C162.454 144.001 102.715 84.2617 29.0234 84.2617C-44.6683 84.2617 -104.407 144.001 -104.407 217.692C-104.407 291.384 -44.6683 351.123 29.0234 351.123Z" stroke="#331FF6" stroke-width="3" stroke-miterlimit="10"/>
                            <path d="M38.6429 372.118C120.091 372.118 186.117 306.091 186.117 224.644C186.117 143.196 120.091 77.1699 38.6429 77.1699C-42.8047 77.1699 -108.831 143.196 -108.831 224.644C-108.831 306.091 -42.8047 372.118 38.6429 372.118Z" stroke="#331FF6" stroke-width="3" stroke-miterlimit="10"/>
                            <path d="M48.2624 393.114C137.466 393.114 209.78 320.8 209.78 231.596C209.78 142.393 137.466 70.0791 48.2624 70.0791C-40.9411 70.0791 -113.255 142.393 -113.255 231.596C-113.255 320.8 -40.9411 393.114 48.2624 393.114Z" stroke="#331FF6" stroke-width="3" stroke-miterlimit="10"/>
                            <path d="M57.8809 414.109C154.84 414.109 233.442 335.507 233.442 238.548C233.442 141.588 154.84 62.9873 57.8809 62.9873C-39.0785 62.9873 -117.68 141.588 -117.68 238.548C-117.68 335.507 -39.0785 414.109 57.8809 414.109Z" stroke="#331FF6" stroke-width="3" stroke-miterlimit="10"/>
                            <path d="M67.5004 435.104C172.216 435.104 257.104 350.216 257.104 245.5C257.104 140.785 172.216 55.8965 67.5004 55.8965C-37.2149 55.8965 -122.104 140.785 -122.104 245.5C-122.104 350.216 -37.2149 435.104 67.5004 435.104Z" stroke="#331FF6" stroke-width="3" stroke-miterlimit="10"/>
                        </g>
                        <g clip-path="url(#clip1_1524_4410)">
                            <path d="M156.425 105L143.5 78.0185L130.574 105L115.518 98.763L125.454 70.546L97.237 80.4825L91 65.4255L117.981 52.5L91 39.5745L97.237 24.5175L125.454 34.454L115.518 6.237L130.574 0L143.5 26.9815L156.425 0L171.486 6.237L161.546 34.454L189.763 24.5175L196 39.5745L169.018 52.5L196 65.4255L189.763 80.4825L161.546 70.546L171.486 98.763L156.425 105Z" fill="url(#paint0_linear_1524_4410)"/>
                        </g>
                        <defs>
                            <linearGradient id="paint0_linear_1524_4410" x1="143.5" y1="0" x2="143.5" y2="105" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#331FF6"/>
                                <stop offset="1" stop-color="#9D05CE"/>
                            </linearGradient>
                            <clipPath id="clip0_1524_4410">
                                <rect width="259" height="383" fill="white" transform="translate(0 54)"/>
                            </clipPath>
                            <clipPath id="clip1_1524_4410">
                                <rect width="105" height="105" fill="white" transform="translate(91)"/>
                            </clipPath>
                        </defs>
                    </svg>

                </div>

                <div className="container">
                    <div className="section-title">
                        <span>POZNAJ RADĘ</span>
                        <h2>Spotkajcie się na GPW<br/>Venture Network Day!</h2>
                    </div>
                    <div className="row">

                      <div className="col-lg-4 col-sm-6">
                        <div className="single-speakers-box">
                          <div className="speakers-image">
                            <img
                              src={require("../../assets/images/gpw/speakers/10.png")}
                              alt="Ignacy Bobruk"
                            />
                          </div>

                          <div className="speakers-content">
                            <h3><Link to="#">Ignacy Bobruk</Link></h3>
                            <span>Head of GPW Venture Network</span>
                            <ul className="social">
                              <li>
                                <a href="https://www.linkedin.com/in/ignacy-b-mba-537a80154/" target="_blank"  rel="noreferrer">
                                  <i className="icofont-linkedin"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-sm-6">
                        <div className="single-speakers-box">
                          <div className="speakers-image">
                            <img
                              src={require("../../assets/images/gpw/speakers/8.png")}
                              alt="Monika Borowiecka"
                            />
                          </div>

                          <div className="speakers-content">
                            <h3><Link to="#">Monika Borowiecka</Link></h3>
                            <span>Partnerka Zarządzająca w Tangent Line VC</span>
                            <ul className="social">
                              <li>
                                <a href="https://www.linkedin.com/in/monika-borowiecka-53801384/?originalSubdomain=pl" target="_blank"  rel="noreferrer">
                                  <i className="icofont-linkedin"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-sm-6">
                        <div className="single-speakers-box">
                          <div className="speakers-image">
                            <img
                              src={require("../../assets/images/gpw/speakers/5.jpg")}
                              alt="Paulina Brym – Ciuba"
                            />
                          </div>

                          <div className="speakers-content">
                            <h3><Link to="#">Paulina Brym – Ciuba</Link></h3>
                            <span>Prezeska Zarządu Fundacji Startup Hub Poland</span>
                            <ul className="social">
                              <li>
                                <a href="https://www.linkedin.com/in/paulina-brym-ciuba-17249466/" target="_blank"  rel="noreferrer">
                                  <i className="icofont-linkedin"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      {/*<div className="col-lg-4 col-sm-6">*/}
                      {/*  <div className="single-speakers-box">*/}
                      {/*    <div className="speakers-image">*/}
                      {/*      <img*/}
                      {/*        src={require("../../assets/images/gpw/speakers/Olgierd Cieślik 1.png")}*/}
                      {/*        alt="Olgierd Cieślik"*/}
                      {/*      />*/}
                      {/*    </div>*/}

                      {/*    <div className="speakers-content">*/}
                      {/*      <h3><Link to="#">Olgierd Cieślik</Link></h3>*/}
                      {/*      <span>Prezes Zarządu Totalizatora Sportowego</span>*/}
                      {/*      <ul className="social">*/}
                      {/*        <li>*/}
                      {/*          <a href="https://www.linkedin.com/in/olgierdcieslik/" target="_blank"  rel="noreferrer">*/}
                      {/*            <i className="icofont-linkedin"></i>*/}
                      {/*          </a>*/}
                      {/*        </li>*/}
                      {/*      </ul>*/}
                      {/*    </div>*/}
                      {/*  </div>*/}
                      {/*</div>*/}

                      <div className="col-lg-4 col-sm-6">
                        <div className="single-speakers-box">
                          <div className="speakers-image">
                            <img
                              src={require("../../assets/images/gpw/speakers/3.png")}
                              alt="Piotr Gębala"
                            />
                          </div>

                          <div className="speakers-content">
                            <h3><Link to="#">Piotr Gębala</Link></h3>
                            <span>Prezes Zarządu GPW Ventures ASI S.A.</span>

                            <ul className="social">
                              <li>
                                <a href="https://www.linkedin.com/in/piotr-g%C4%99bala-2022783/" target="_blank"  rel="noreferrer">
                                  <i className="icofont-linkedin"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-sm-6">
                        <div className="single-speakers-box">
                          <div className="speakers-image">
                            <img
                              src={require("../../assets/images/gpw/speakers/9.jpg")}
                              alt="Robert Ługowski"
                            />
                          </div>

                          <div className="speakers-content">
                            <h3><Link to="#">Robert Ługowski</Link></h3>
                            <span>Współzałożyciel COBIN Angels</span>

                            <ul className="social">
                              <li>
                                <a href="https://www.linkedin.com/in/robertlugowski/" target="_blank"  rel="noreferrer">
                                  <i className="icofont-linkedin"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-sm-6">
                        <div className="single-speakers-box">
                          <div className="speakers-image">
                            <img
                              src={require("../../assets/images/gpw/speakers/7.png")}
                              alt="Adrian Migoń"
                            />
                          </div>

                          <div className="speakers-content">
                            <h3><Link to="#">Adrian Migoń</Link></h3>
                            <span>Prezes Youth Business Poland</span>

                            <ul className="social">
                              <li>
                                <a href="https://www.linkedin.com/in/adrian-migo%C5%84-01629216/?originalSubdomain=pl" target="_blank"  rel="noreferrer">
                                  <i className="icofont-linkedin"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-sm-6">
                        <div className="single-speakers-box">
                          <div className="speakers-image">
                            <img
                              src={require("../../assets/images/gpw/speakers/2.jpg")}
                              alt="Michał Olszewski"
                            />
                          </div>

                          <div className="speakers-content">
                            <h3><Link to="#">Michał Olszewski</Link></h3>
                            <span>Partner w Movens Capital, Twórca Lewiatan Business Angels</span>

                            <ul className="social">
                              <li>
                                <a href="https://www.linkedin.com/in/miolszewski/" target="_blank"  rel="noreferrer">
                                  <i className="icofont-linkedin"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-sm-6">
                        <div className="single-speakers-box">
                          <div className="speakers-image">
                            <img
                              src={require("../../assets/images/gpw/speakers/4.png")}
                              alt="Hubert Pachciarek"
                            />
                          </div>

                          <div className="speakers-content">
                            <h3><Link to="#">Hubert Pachciarek</Link></h3>
                            <span>Dyrektor Centrum Transferu Technologii Pomorskiego Uniwersytetu Medycznego w Szczecinie</span>

                            <ul className="social">
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-sm-6">
                            <div className="single-speakers-box">
                                <div className="speakers-image">
                                    <img 
                                        src={require("../../assets/images/gpw/speakers/1.jpg")}
                                        alt="Kacper Raciborski"
                                    />
                                </div>

                                <div className="speakers-content">
                                    <h3><Link to="#">Kacper Raciborski</Link></h3>
                                  <span>Prezes Innovations Hub</span>

                                  <ul className="social">
                                        <li>
                                            <a href="https://www.linkedin.com/in/kacper-raciborski/" target="_blank"  rel="noreferrer">
                                                <i className="icofont-linkedin"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                      <div className="col-lg-4 col-sm-6">
                        <div className="single-speakers-box">
                          <div className="speakers-image">
                            <img
                              src={require("../../assets/images/gpw/speakers/6.png")}
                              alt="Tomasz Snażyk"
                            />
                          </div>

                          <div className="speakers-content">
                            <h3><Link to="#">Tomasz Snażyk</Link></h3>
                            <span>Współinicjator-współzałożyciel Fundacji Startup Poland</span>

                            <ul className="social">
                              <li>
                                <a href="https://www.linkedin.com/in/tomek-snazyk-8245519/" target="_blank"  rel="noreferrer">
                                  <i className="icofont-linkedin"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>

                        {/*<div className="col-lg-4 col-sm-6">*/}
                        {/*    <div className="single-speakers-box">*/}
                        {/*        <div className="speakers-image">*/}
                        {/*            <img */}
                        {/*                src={require("../../assets/images/speakers6.jpg")} */}
                        {/*                alt="speaker" */}
                        {/*            />*/}
                        {/*        </div>*/}

                        {/*        <div className="speakers-content">*/}
                        {/*            <h3><Link to="#">Abbie Edie</Link></h3>*/}
                        {/*            <span>Lead Designer</span>*/}

                        {/*            <ul className="social">*/}
                        {/*                <li>*/}
                        {/*                    <Link to="#">*/}
                        {/*                        <i className="icofont-facebook"></i>*/}
                        {/*                    </Link>*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    <Link to="#">*/}
                        {/*                        <i className="icofont-twitter"></i>*/}
                        {/*                    </Link>*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    <Link to="#">*/}
                        {/*                        <i className="icofont-linkedin"></i>*/}
                        {/*                    </Link>*/}
                        {/*                </li>*/}
                        {/*                <li>*/}
                        {/*                    <Link to="#">*/}
                        {/*                        <i className="icofont-instagram"></i>*/}
                        {/*                    </Link>*/}
                        {/*                </li>*/}
                        {/*            </ul>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Speakers;