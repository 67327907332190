import React from 'react';
import lax from 'lax.js';

class About extends React.Component {
    
    constructor(props) {
        super(props)
        lax.setup()
    
        document.addEventListener('scroll', function(x) {
            lax.update(window.scrollY)
        }, false)
    
        lax.update(window.scrollY)
    }

    render(){
        return (
            <section className="about-area-three ptb-120 bg-image">
                <div className="container">
                    <div className="row h-100 align-items-center">
                      <div className="col-lg-6">
                        <div className="about-content">
                          <span>O WYDARZENIU</span>
                          <h2>GPW Venture Network Day</h2>
                          <h6>
                            Kiedy myślisz o innowacji, myślisz o przyszłości. Przyszłość rodzi się tutaj i teraz, na GPW Venture Network Day. To miejsce, gdzie dynamika rynku spotyka się z pasją przedsiębiorców, a wizje stają się realnymi projektami.
                          </h6>
                          <p>
                            Nie jesteśmy kolejnym wydarzeniem, jesteśmy mostem łączącym startupy z światem giełdowym. Przedstawiciele czołowych organizacji, inwestorzy, liderzy sektorów oraz startupy tworzą wyjątkowy ekosystem wymiany wiedzy, doświadczeń i kapitału. To miejsce, gdzie możesz nie tylko nawiązać wartościowe kontakty, ale również dołączyć do grona liderów kształtujących przyszłość polskiego ekosystemu startupowego. Chcesz zrozumieć, w którą stronę zmierza świat innowacji? GPW Venture Network Day to Twoja przepustka do świata innowacji.
                            <br/><br/>
                          </p>
                          <h6>
                            GPW Venture Network Day to:
                          </h6>
                          <ul>
                            <li>
                              • Interakcja z kluczowymi osobistościami polskiego ekosystemu startupowego
                            </li>
                            <li>
                              • Odkrywanie innowacyjnych rozwiązań i projektów
                            </li>
                            <li>
                              • Nawiązywanie wartościowych kontaktów biznesowych
                            </li>
                            <li>
                              • Wspieranie rozwoju najbardziej obiecujących innowacji
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6">
                          <div className="about-image">
                              <img
                                  src={require("../../assets/images/gpw/osoby-biznesu.png")}
                                  className="about-img1"
                                  alt="Osoby rozmawiają o biznesie - Networking"
                              />
                          </div>
                      </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default About;