import React from 'react';
import { Link, withRouter, NavLink } from 'react-router-dom';
import logo from '../../assets/images/main/logo.png';
 
class Navigation extends React.Component {

    state = {
        collapsed: true,
        isOpen: false
    };

    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    componentDidMount() {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
                window.history.pushState("", document.title, window.location.pathname);
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    }

    toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

    componentDidUpdate(nextProps) {
        if (this.props.match.path !== nextProps.match.path) {
            // this.onRouteChanged();
            console.log('OK')
        }
    }

    onRouteChanged = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render(){
        const { collapsed } = this.state;
        const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
        const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';
        const menuClass = `dropdown-menu${this.state.isOpen ? " show" : ""}`;
        return (
            <header id="header" className="header-area">
                <div id="navbar" className="elkevent-nav">
                    <nav className="navbar navbar-expand-md navbar-light">
                        <div className="container">
                            <Link className="navbar-brand" to="/">
                                <svg className="logo-menu" width="100%" height="100%" viewBox="0 0 170 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_189_145)">
                                        <path d="M89.7363 0H100.191C110.423 0 115.985 5.24977 115.985 14.6813C115.985 24.1128 110.425 29.3626 100.191 29.3626H89.7363V0ZM99.9684 22.3779C105.397 22.3779 107.799 19.8425 107.799 14.6813C107.799 9.52004 105.397 6.98463 99.9684 6.98463H97.61V22.3779H99.9684Z"/>
                                        <path d="M126.708 0H134.493L145.483 29.3626H136.897L135.205 24.2899H125.595L123.905 29.3626H115.719L126.708 0ZM133.114 17.8854L130.4 8.8099L127.686 17.8854H133.113H133.114Z"/>
                                        <path d="M151.358 17.5294L141.169 0H150.244L155.628 9.96647L160.966 0H169.42L159.231 17.5294V29.3626H151.356V17.5294H151.358Z"/>
                                        <path d="M0 0.529297H3.6675L6.18972 8.34839L8.69123 0.529297H12.1835L7.66275 13.3345H4.51891L0 0.529297Z"/>
                                        <path d="M13.1162 0.529297H22.8943V3.57518H16.5501V5.41929H21.9638V8.27116H16.5501V10.2886H23.1072V13.3345H13.1162V0.529297Z"/>
                                        <path d="M25.124 0.529297H28.5184L33.252 7.84357V0.529297H36.5315V13.3345H33.2728L28.4035 5.80733V13.3345H25.124V0.529297Z"/>
                                        <path d="M41.5571 3.61474H37.8896V0.529297H48.6567V3.61474H44.9892V13.3345H41.5553V3.61474H41.5571Z"/>
                                        <path d="M49.7061 8.50285V0.529297H53.14V8.27116C53.14 9.68767 53.7616 10.347 55.1385 10.347C56.5155 10.347 57.0787 9.70651 57.0787 8.27116V0.529297H60.3582V8.50285C60.3582 11.9368 58.6704 13.5661 55.0425 13.5661C51.4145 13.5661 49.7079 11.9368 49.7079 8.50285H49.7061Z"/>
                                        <path d="M62.5693 0.529297H68.0602C71.319 0.529297 72.969 1.96465 72.969 4.73929C72.969 6.62107 72.2118 7.88312 70.6992 8.50285L73.8223 13.3345H69.7291L67.1108 8.94928H66.0051V13.3345H62.5712V0.529297H62.5693ZM67.8266 6.19348C68.9719 6.19348 69.4748 5.76588 69.4748 4.81652C69.4748 3.86715 68.97 3.43956 67.8266 3.43956H66.0033V6.19348H67.8266Z"/>
                                        <path d="M75.1992 0.529297H84.9773V3.57518H78.6331V5.41929H84.0468V8.27116H78.6331V10.2886H85.1902V13.3345H75.1992V0.529297Z"/>
                                        <path d="M1.46777 17.7441H4.53061L8.80088 24.3426V17.7441H11.7582V29.2966H8.81783L4.42513 22.5041V29.2966H1.46777V17.7441Z"/>
                                        <path d="M14.037 17.7441H22.8582V20.4924H17.1338V22.1557H22.0181V24.7288H17.1338V26.5484H23.0504V29.2966H14.0352V17.7441H14.037Z"/>
                                        <path d="M27.13 20.5263H23.8223V17.7422H33.5363V20.5263H30.2286V29.2966H27.13V20.5263Z"/>
                                        <path d="M33.8172 17.7441H37.0383L38.7016 24.5536L40.6097 17.7441H42.9549L44.88 24.6063L46.5245 17.7441H49.5703L46.2438 29.2966H43.4955L41.7098 22.8545L39.8713 29.2966H37.14L33.8135 17.7441H33.8172Z"/>
                                        <path d="M49.8174 23.5194C49.8174 20.0196 52.3547 17.5332 55.9431 17.5332C59.5314 17.5332 62.0687 20.0196 62.0687 23.5194C62.0687 27.0193 59.5314 29.5057 55.9431 29.5057C52.3547 29.5057 49.8174 27.0193 49.8174 23.5194ZM58.8496 23.5194C58.8496 21.6282 57.7118 20.4039 55.9431 20.4039C54.1743 20.4039 53.0366 21.6282 53.0366 23.5194C53.0366 25.4106 54.1743 26.635 55.9431 26.635C57.7118 26.635 58.8496 25.4106 58.8496 23.5194Z"/>
                                        <path d="M63.664 17.7441H68.618C71.5584 17.7441 73.0465 19.0401 73.0465 21.5435C73.0465 23.2407 72.3646 24.3784 70.999 24.9397L73.8169 29.2985H70.1231L67.7591 25.3428H66.7607V29.2985H63.6621V17.746L63.664 17.7441ZM68.4071 22.8545C69.4393 22.8545 69.8952 22.4702 69.8952 21.6113C69.8952 20.7523 69.4393 20.3681 68.4071 20.3681H66.7626V22.8545H68.4071Z"/>
                                        <path d="M75.0605 17.7441H78.1591V22.6624L81.8002 17.7441H85.3528L81.1862 23.3273L85.3867 29.2966H81.6062L78.1572 24.3087V29.2966H75.0586V17.7441H75.0605Z"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M109.876 33.9192H113.139L107.16 41.9775H103.897"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M119.017 41.9775L115.783 41.9869L112.334 37.4209H115.57"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M108.935 41.9775H112.197L115.571 37.4209H112.335"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M119.017 41.9775L115.783 41.9869L109.84 50H113.112"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M120.838 41.9775L122.529 44.2416L118.185 49.9906H114.896"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M118.185 49.9906H114.896L108.935 41.9775H112.197"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M109.84 50H113.112L107.16 41.9775H103.897"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M139.747 37.4209H135.422V47.7905H137.944V44.8708H139.747C142.288 44.8708 143.748 43.4656 143.748 41.0356C143.748 38.7244 142.288 37.4228 139.747 37.4228M139.617 42.8025H137.944V39.5023H139.617C140.726 39.5023 141.363 40.0467 141.363 41.1298C141.363 42.2129 140.724 42.8025 139.617 42.8025Z"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M156.763 37.4209L154.849 45.046L152.899 37.4209H150.432L148.527 45.046L146.587 37.4209H143.888L147.141 47.7905H149.748L151.624 40.6193L153.472 47.7905H156.069L159.349 37.4209"/>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M129.692 39.5381C130.617 39.5381 131.623 39.9638 132.345 40.6664L133.769 38.8732C132.742 37.9219 131.19 37.3305 129.711 37.3305C126.579 37.3305 124.239 39.5946 124.239 42.6085C124.239 45.6224 126.522 47.9035 129.544 47.9035C130.93 47.9035 132.612 47.3403 133.675 46.5548V42.5162H130.097V44.43H131.493V45.1967C130.902 45.5018 130.246 45.6958 129.738 45.6958C128.093 45.6958 126.826 44.3641 126.826 42.5991C126.807 40.8793 128.056 39.54 129.691 39.54"/>
                                        <path d="M1.49798 37.4774H5.77201C8.23208 37.4774 9.49414 38.5492 9.49414 40.6325C9.49414 42.7159 8.23208 43.7877 5.77201 43.7877H2.83726V47.8884H1.49609V37.4774H1.49798ZM5.75694 42.5558C7.42964 42.5558 8.1379 41.9718 8.1379 40.6307C8.1379 39.2895 7.42775 38.7055 5.75694 38.7055H2.83915V42.5539H5.75694V42.5558Z"/>
                                        <path d="M10.6631 42.682C10.6631 39.5117 12.8707 37.2871 16.0579 37.2871C19.2451 37.2871 21.4527 39.5117 21.4527 42.682C21.4527 45.8522 19.2451 48.0768 16.0579 48.0768C12.8707 48.0768 10.6631 45.8522 10.6631 42.682ZM20.0795 42.682C20.0795 40.2219 18.4539 38.5341 16.0579 38.5341C13.6619 38.5341 12.0363 40.2219 12.0363 42.682C12.0363 45.142 13.6619 46.8298 16.0579 46.8298C18.4539 46.8298 20.0795 45.142 20.0795 42.682Z"/>
                                        <path d="M22.0204 37.4774H23.4237L25.9629 45.7109L28.5341 37.4774H29.5908L32.1771 45.6638L34.7012 37.4774H36.0574L32.8232 47.8884H31.5762L29.0521 39.8433L26.5129 47.8884H25.2509L22.0166 37.4774H22.0204Z"/>
                                        <path d="M37.5264 37.4774H45.272V38.7074H38.8675V41.9097H44.5148V43.1397H38.8675V46.6565H45.4302V47.8865H37.5282V37.4755L37.5264 37.4774Z"/>
                                        <path d="M47.4325 37.4774H51.8328C54.2307 37.4774 55.4758 38.5021 55.4758 40.5214C55.4758 42.1771 54.6244 43.1717 52.9988 43.4712L56.233 47.8884H54.5603L51.3901 43.5654H48.7718V47.8884H47.4307V37.4774H47.4325ZM51.8328 42.3354C53.4094 42.3354 54.1196 41.7835 54.1196 40.5214C54.1196 39.2593 53.4094 38.7074 51.8328 38.7074H48.7718V42.3354H51.8328Z"/>
                                        <path d="M57.749 37.4774H65.4947V38.7074H59.0902V41.9097H64.7374V43.1397H59.0902V46.6565H65.6529V47.8865H57.7509V37.4755L57.749 37.4774Z"/>
                                        <path d="M67.6553 37.4774H70.9686C74.5966 37.4774 76.6309 39.3705 76.6309 42.6838C76.6309 45.9972 74.5966 47.8884 70.9686 47.8884H67.6553V37.4774ZM70.9517 46.6584C73.7753 46.6584 75.2577 45.2852 75.2577 42.6838C75.2577 40.0825 73.7753 38.7093 70.9517 38.7093H68.9964V46.6603H70.9517V46.6584Z"/>
                                        <path d="M81.8682 37.4774H86.3004C88.5552 37.4774 89.7231 38.3759 89.7231 40.1597C89.7231 41.2636 89.2653 41.9888 88.3499 42.3523V42.3843C89.5968 42.7479 90.2279 43.6464 90.2279 45.0026C90.2279 46.8957 89.0299 47.8884 86.7261 47.8884H81.8682V37.4774ZM86.2383 41.9417C87.7527 41.9417 88.3838 41.4689 88.3838 40.3161C88.3838 39.1633 87.7527 38.7074 86.1761 38.7074H83.2112V41.9417H86.2402H86.2383ZM86.5227 46.6584C88.1784 46.6584 88.8566 46.1385 88.8566 44.9235C88.8566 43.7086 88.1464 43.1717 86.3645 43.1717H83.2093V46.6584H86.5227Z"/>
                                        <path d="M95.7161 43.281V47.8865H94.3749V43.296L90.4004 37.4755H92.009L95.07 41.9549L98.131 37.4755H99.6925L95.718 43.281H95.7161Z"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_189_145">
                                            <rect width="169.421" height="50"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </Link>

                            <div id="navbarSupportedContent">
                                <ul className="navbar-nav ms-auto">
                                    {/*<li style={{marginRight: '1rem'}}>*/}
                                    {/*    <a href="https://www.gpw.pl/gvnd" className="btn-gpw btn-gpw-small btn-gpw-main">Bilet onsite</a>*/}
                                    {/*</li>*/}
                                    <li>
                                        <a href="https://www.gpw.pl/gvnd" className="btn-gpw btn-gpw-small btn-gpw-sec">Bilet online</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
        );
    }
}
 
export default withRouter(Navigation);