import React from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";
import vido from '../../assets/video/GPWVND_Animacja_v6.mp4'
import vidoP from '../../assets/video/vido_placeholder.png'

class FunFact extends React.Component {
    state = {
        didViewCountUp: false
    };

    onVisibilityChange = isVisible => {
        if (isVisible) {
            this.setState({ didViewCountUp: true });
        }
    };

    render(){
        return (
          <>
            <section className="funfacts-area ptb-120">
              <div className="container">
                <div className="row">
                  {/*<div className="tmp-fun-ctn" style={{textAlign: 'center'}}>*/}
                  {/*    <h2>*/}
                  {/*        Tworzymy przyszłość. Dołącz do nas!*/}
                  {/*    </h2>*/}
                  {/*</div>*/}

                  <div className="col-lg-3 col-6 col-sm-6">
                    <div className="single-funfact">
                      <div>
                        <h3 className="odometer">
                          <VisibilitySensor
                            onChange={this.onVisibilityChange}
                            offset={{
                              top: 10
                            }}
                            delayedCall
                          >
                            <CountUp
                              start={0}
                              end={
                                this.state.didViewCountUp
                                  ? 400
                                  : 100
                              }
                              duration={3}
                              suffix="+"
                            />
                          </VisibilitySensor>
                        </h3>
                        <div className="icon">
                          <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_242_14064)">
                              <path d="M11.8613 14.3719L14.4113 22.8699L11.8613 14.3719ZM25.6253 34.0879L34.1273 36.6379L25.6253 34.0879Z" fill="url(#paint0_linear_242_14064)"/>
                              <path d="M11.8613 14.3719L14.4113 22.8699M25.6253 34.0879L34.1273 36.6379" stroke="#FBFAFF" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M23.3652 25.134L34.8832 13.616" stroke="#FBFAFF" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M8 11.5C8 11.894 8.0776 12.2841 8.22836 12.6481C8.37913 13.012 8.6001 13.3427 8.87868 13.6213C9.15726 13.8999 9.48797 14.1209 9.85195 14.2716C10.2159 14.4224 10.606 14.5 11 14.5C11.394 14.5 11.7841 14.4224 12.1481 14.2716C12.512 14.1209 12.8427 13.8999 13.1213 13.6213C13.3999 13.3427 13.6209 13.012 13.7716 12.6481C13.9224 12.2841 14 11.894 14 11.5C14 11.106 13.9224 10.7159 13.7716 10.3519C13.6209 9.98797 13.3999 9.65726 13.1213 9.37868C12.8427 9.1001 12.512 8.87913 12.1481 8.72836C11.7841 8.5776 11.394 8.5 11 8.5C10.606 8.5 10.2159 8.5776 9.85195 8.72836C9.48797 8.87913 9.15726 9.1001 8.87868 9.37868C8.6001 9.65726 8.37913 9.98797 8.22836 10.3519C8.0776 10.7159 8 11.106 8 11.5Z" fill="url(#paint1_linear_242_14064)" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M34 11.5C34 12.2957 34.3161 13.0587 34.8787 13.6213C35.4413 14.1839 36.2044 14.5 37 14.5C37.7956 14.5 38.5587 14.1839 39.1213 13.6213C39.6839 13.0587 40 12.2957 40 11.5C40 10.7044 39.6839 9.94129 39.1213 9.37868C38.5587 8.81607 37.7956 8.5 37 8.5C36.2044 8.5 35.4413 8.81607 34.8787 9.37868C34.3161 9.94129 34 10.7044 34 11.5Z" fill="url(#paint2_linear_242_14064)" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M34 37.5C34 38.2956 34.3161 39.0587 34.8787 39.6213C35.4413 40.1839 36.2044 40.5 37 40.5C37.7956 40.5 38.5587 40.1839 39.1213 39.6213C39.6839 39.0587 40 38.2956 40 37.5C40 36.7044 39.6839 35.9413 39.1213 35.3787C38.5587 34.8161 37.7956 34.5 37 34.5C36.2044 34.5 35.4413 34.8161 34.8787 35.3787C34.3161 35.9413 34 36.7044 34 37.5Z" fill="url(#paint3_linear_242_14064)" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M8 31.5C8 33.8869 8.94821 36.1761 10.636 37.864C12.3239 39.5518 14.6131 40.5 17 40.5C19.3869 40.5 21.6761 39.5518 23.364 37.864C25.0518 36.1761 26 33.8869 26 31.5C26 29.1131 25.0518 26.8239 23.364 25.136C21.6761 23.4482 19.3869 22.5 17 22.5C14.6131 22.5 12.3239 23.4482 10.636 25.136C8.94821 26.8239 8 29.1131 8 31.5Z" fill="url(#paint4_linear_242_14064)" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                            <defs>
                              <linearGradient id="paint0_linear_242_14064" x1="22.9943" y1="14.3719" x2="22.9943" y2="36.6379" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" stopOpacity="0.6"/>
                                <stop offset="1" stopColor="white" stopOpacity="0.2"/>
                              </linearGradient>
                              <linearGradient id="paint1_linear_242_14064" x1="11" y1="8.5" x2="11" y2="14.5" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" stopOpacity="0.6"/>
                                <stop offset="1" stopColor="white" stopOpacity="0.2"/>
                              </linearGradient>
                              <linearGradient id="paint2_linear_242_14064" x1="37" y1="8.5" x2="37" y2="14.5" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" stopOpacity="0.6"/>
                                <stop offset="1" stopColor="white" stopOpacity="0.2"/>
                              </linearGradient>
                              <linearGradient id="paint3_linear_242_14064" x1="37" y1="34.5" x2="37" y2="40.5" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" stopOpacity="0.6"/>
                                <stop offset="1" stopColor="white" stopOpacity="0.2"/>
                              </linearGradient>
                              <linearGradient id="paint4_linear_242_14064" x1="17" y1="22.5" x2="17" y2="40.5" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" stopOpacity="0.6"/>
                                <stop offset="1" stopColor="white" stopOpacity="0.2"/>
                              </linearGradient>
                              <clipPath id="clip0_242_14064">
                                <rect width="48" height="48" fill="white" transform="translate(0 0.5)"/>
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </div>
                      <p>UCZESTNIKÓW</p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-6 col-sm-6">
                    <div className="single-funfact">
                      <div>
                        <h3 className="odometer">
                          <VisibilitySensor
                            onChange={this.onVisibilityChange}
                            offset={{
                              top: 10
                            }}
                            delayedCall
                          >
                            <CountUp
                              start={0}
                              end={
                                this.state.didViewCountUp
                                  ? 50
                                  : 10
                              }
                              duration={3}
                              suffix="+"
                            />
                          </VisibilitySensor>
                        </h3>
                        <div className="icon">
                          <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_242_14070)">
                              <path d="M10 14.5C10 16.6217 10.8429 18.6566 12.3431 20.1569C13.8434 21.6571 15.8783 22.5 18 22.5C20.1217 22.5 22.1566 21.6571 23.6569 20.1569C25.1571 18.6566 26 16.6217 26 14.5C26 12.3783 25.1571 10.3434 23.6569 8.84315C22.1566 7.34285 20.1217 6.5 18 6.5C15.8783 6.5 13.8434 7.34285 12.3431 8.84315C10.8429 10.3434 10 12.3783 10 14.5Z" fill="url(#paint0_linear_242_14070)" stroke="#FBFAFF" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M6 42.5V38.5C6 36.3783 6.84285 34.3434 8.34315 32.8431C9.84344 31.3429 11.8783 30.5 14 30.5H22C24.1217 30.5 26.1566 31.3429 27.6569 32.8431C29.1571 34.3434 30 36.3783 30 38.5V42.5" fill="url(#paint1_linear_242_14070)"/>
                              <path d="M6 42.5V38.5C6 36.3783 6.84285 34.3434 8.34315 32.8431C9.84344 31.3429 11.8783 30.5 14 30.5H22C24.1217 30.5 26.1566 31.3429 27.6569 32.8431C29.1571 34.3434 30 36.3783 30 38.5V42.5" stroke="#FBFAFF" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M32 6.76001C33.7208 7.20061 35.2461 8.20141 36.3353 9.60463C37.4245 11.0078 38.0157 12.7337 38.0157 14.51C38.0157 16.2864 37.4245 18.0122 36.3353 19.4154C35.2461 20.8186 33.7208 21.8194 32 22.26" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M42 42.5V38.5C41.9898 36.7344 41.3958 35.0216 40.3105 33.6289C39.2252 32.2361 37.7097 31.2414 36 30.8" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                            <defs>
                              <linearGradient id="paint0_linear_242_14070" x1="18" y1="6.5" x2="18" y2="22.5" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" stopOpacity="0.6"/>
                                <stop offset="1" stopColor="white" stopOpacity="0.2"/>
                              </linearGradient>
                              <linearGradient id="paint1_linear_242_14070" x1="18" y1="30.5" x2="18" y2="42.5" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" stopOpacity="0.6"/>
                                <stop offset="1" stopColor="white" stopOpacity="0.2"/>
                              </linearGradient>
                              <clipPath id="clip0_242_14070">
                                <rect width="48" height="48" fill="white" transform="translate(0 0.5)"/>
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                      </div>
                      <p>Ekspertów</p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-6 col-sm-6">
                    <div className="single-funfact">
                      <div>
                        <h3 className="odometer">
                          <VisibilitySensor
                            onChange={this.onVisibilityChange}
                            offset={{
                              top: 10
                            }}
                            delayedCall
                          >
                            <CountUp
                              start={0}
                              end={
                                this.state.didViewCountUp
                                  ? 20
                                  : 0
                              }
                              duration={3}
                              suffix="+"
                            />
                          </VisibilitySensor>
                        </h3>
                        <div className="icon">
                          <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_242_14076)">
                              <path d="M30 10.5V14.5V10.5Z" fill="url(#paint0_linear_242_14076)"/>
                              <path d="M30 10.5V14.5" stroke="#FBFAFF" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M30 22.5V26.5" stroke="#FBFAFF" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M30 34.5V38.5" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M10 10.5H38C39.0609 10.5 40.0783 10.9214 40.8284 11.6716C41.5786 12.4217 42 13.4391 42 14.5V20.5C40.9391 20.5 39.9217 20.9214 39.1716 21.6716C38.4214 22.4217 38 23.4391 38 24.5C38 25.5609 38.4214 26.5783 39.1716 27.3284C39.9217 28.0786 40.9391 28.5 42 28.5V34.5C42 35.5609 41.5786 36.5783 40.8284 37.3284C40.0783 38.0786 39.0609 38.5 38 38.5H10C8.93913 38.5 7.92172 38.0786 7.17157 37.3284C6.42143 36.5783 6 35.5609 6 34.5V28.5C7.06087 28.5 8.07828 28.0786 8.82843 27.3284C9.57857 26.5783 10 25.5609 10 24.5C10 23.4391 9.57857 22.4217 8.82843 21.6716C8.07828 20.9214 7.06087 20.5 6 20.5V14.5C6 13.4391 6.42143 12.4217 7.17157 11.6716C7.92172 10.9214 8.93913 10.5 10 10.5Z" fill="url(#paint1_linear_242_14076)" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                            <defs>
                              <linearGradient id="paint0_linear_242_14076" x1="30.5" y1="10.5" x2="30.5" y2="14.5" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" stopOpacity="0.6"/>
                                <stop offset="1" stopColor="white" stopOpacity="0.2"/>
                              </linearGradient>
                              <linearGradient id="paint1_linear_242_14076" x1="24" y1="10.5" x2="24" y2="38.5" gradientUnits="userSpaceOnUse">
                                <stop stopColor="white" stopOpacity="0.6"/>
                                <stop offset="1" stopColor="white" stopOpacity="0.2"/>
                              </linearGradient>
                              <clipPath id="clip0_242_14076">
                                <rect width="48" height="48" fill="white" transform="translate(0 0.5)"/>
                              </clipPath>
                            </defs>
                          </svg>

                        </div>
                      </div>
                      <p>Startupów</p>
                    </div>
                  </div>

                  {/*<div className="col-lg-3 col-6 col-sm-6">*/}
                  {/*    <div className="single-funfact">*/}
                  {/*        <div>*/}
                  {/*            <h3 className="odometer">*/}
                  {/*                <VisibilitySensor*/}
                  {/*                    onChange={this.onVisibilityChange}*/}
                  {/*                    offset={{*/}
                  {/*                        top: 10*/}
                  {/*                    }}*/}
                  {/*                    delayedCall*/}
                  {/*                >*/}
                  {/*                    <CountUp*/}
                  {/*                        start={0}*/}
                  {/*                        end={*/}
                  {/*                            this.state.didViewCountUp*/}
                  {/*                                ? 99*/}
                  {/*                                : 0*/}
                  {/*                        }*/}
                  {/*                        duration={3}*/}
                  {/*                        suffix="+"*/}
                  {/*                    />*/}
                  {/*                </VisibilitySensor>*/}
                  {/*            </h3>*/}
                  {/*            <div className="icon">*/}
                  {/*                <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                  {/*                    <g clipPath="url(#clip0_242_14082)">*/}
                  {/*                        <path d="M6.5 24.5C6.5 26.8638 6.96558 29.2044 7.87017 31.3883C8.77475 33.5722 10.1006 35.5565 11.7721 37.2279C13.4435 38.8994 15.4278 40.2253 17.6117 41.1298C19.7956 42.0344 22.1362 42.5 24.5 42.5C26.8638 42.5 29.2044 42.0344 31.3883 41.1298C33.5722 40.2253 35.5565 38.8994 37.2279 37.2279C38.8994 35.5565 40.2253 33.5722 41.1298 31.3883C42.0344 29.2044 42.5 26.8638 42.5 24.5C42.5 19.7261 40.6036 15.1477 37.2279 11.7721C33.8523 8.39642 29.2739 6.5 24.5 6.5C19.7261 6.5 15.1477 8.39642 11.7721 11.7721C8.39642 15.1477 6.5 19.7261 6.5 24.5Z" fill="url(#paint0_linear_242_14082)" stroke="#FBFAFF" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>*/}
                  {/*                        <path d="M24.5 14.5V24.5L30.5 30.5" stroke="#FBFAFF" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round"/>*/}
                  {/*                    </g>*/}
                  {/*                    <defs>*/}
                  {/*                        <linearGradient id="paint0_linear_242_14082" x1="24.5" y1="6.5" x2="24.5" y2="42.5" gradientUnits="userSpaceOnUse">*/}
                  {/*                            <stop stopColor="white" stopOpacity="0.6"/>*/}
                  {/*                            <stop offset="1" stopColor="white" stopOpacity="0.2"/>*/}
                  {/*                        </linearGradient>*/}
                  {/*                        <clipPath id="clip0_242_14082">*/}
                  {/*                            <rect width="48" height="48" fill="white" transform="translate(0.5 0.5)"/>*/}
                  {/*                        </clipPath>*/}
                  {/*                    </defs>*/}
                  {/*                </svg>*/}
                  {/*            </div>*/}
                  {/*        </div>*/}
                  {/*        <p>Startupów</p>*/}
                  {/*    </div>*/}
                  {/*</div>*/}
                </div>
              </div>
            </section>

            <section className="speakers-area-two ptb-120" style={{position: 'relative'}}>
              <div className="container">
                <div style={{display: "flex", alignItems: 'center', justifyContent: 'center'}}>
                  <div style={{maxWidth: '800px', width: '100%'}}>
                    <video
                      src={vido}
                      poster={vidoP}
                      style={{objectFit: 'fill', width: '100%'}}
                      controls="controls" autoPlay="false"/>
                  </div>
                </div>
              </div>
            </section>
            </>
        );
    }
}
 
export default FunFact;